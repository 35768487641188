import Vue from 'vue'
import Vuex from 'vuex'
import menu from './menu/menu'

// Chat
import avaliations from './attendance/avaliations/avaliations'
import automatic_messages from './attendance/chat/automatic_messages'
import bonus from './attendance/bonus/bonus'
import mass_messages from './attendance/chat/mass_messages'
import chat from './attendance/chat/chat'
import contact_chat from './attendance/chat/contact'
import manage_annotated from './attendance/report/manage_annotated'
import manage_attendance from './attendance/report/manage_attendance'
import message from './attendance/chat/message'
import status_instance from './attendance/chat/status_instance'
import attendance_dashboard from './attendance/dashboard/attendance_dashboard'
import managers from './commercial/managers/managers'
import manager from './commercial/managers/manager'
import contacts from './attendance/contact/contact'
import occurrences from './attendance/occurrences/occurrences'

// Login
import login from './login/login'
import users from './users/users'

// Profiles
import profiles from './profile/profiles'
import profile from './profile/profile'

// Routes
import routes from './route/routes'

// Menu
import menus from './menus/menus'

// Instances
import instances from './instances/instances'
import slots from './slots/slots'

// Internal
import absences from './internal/absences/absences'
import candidates from './internal/jobs/candidates'
import clubs from './clubs/clubs'
import collaborator from './internal/collaborators/collaborator'
import collaborators from './internal/collaborators/collaborators'
import collaborator_documents from './internal/collaborators/collaborator_documents'
import collaborators_equipments from './internal/collaborators/collaborators_equipments'
import companies from './internal/companies/companies'
import equipments from './internal/equipments/equipments'
import jobs from './internal/jobs/jobs'
import league from './internal/league/league'
import meetings from './internal/meetings/meetings'
import player_approval from './internal/player_approval/player_approval'
import profile_edit from './internal/profile_edit/profile_edit'
import releases from './internal/releases/releases'
import requests from './internal/requests/requests'
import system_services from './internal/system_services/system_services'
import tomticket from './internal/tomticket/tomticket'

// Human Resources
import emails from './human_resources/emails/emails'

// Financial
import auto_attendance from './financial/auto_attendance/auto_attendance'
import annotateds from './financial/annotateds/annotateds'
import agent_group from './commercial/agent_groups/agent_groups'
import agents_closure from './financial/weekly_closure/agents_closure'
import agents_closure_detail from './financial/weekly_closure/agents_closure_detail'
import balance from './financial/balance/balance'
import bank from './financial/bank'
import banks_bacen from './financial/banks_bacen/banks_bacen'
import bank_sub_accounts from './financial/bank_sub_accounts/bank_sub_accounts'
import billings from './financial/billings/billings'
import bills_to_receive from './financial/bills/bills_to_receive/bills_to_receive'
import bills_to_pay from './financial/bills/bills_to_pay/bills_to_pay'
import bills_items from './financial/bills/bills_items/bills_items'
import bots from './financial/bots/bots'
import chips_transfer from './financial/chips_transfer/chips_transfer'
import club_closure from './financial/weekly_closure/club_closure'
import cost_center from './financial/cost_center/cost_center'
import deposit from './financial/deposit/deposit'
import documents_agent from './commercial/documents/documents_agent'
import expense from './financial/expense/expense'
import expense_recurrent from './financial/expense_recurrent/expense_recurrent'
import expense_types from './expense_types/expense_types'
import extract from './financial/extract/extract'
import fail from './financial/fail/fail'
import financial_checks from './financial/financial_check/financial_checks'
import financial_conciliation from './financial/financial_conciliation/financial_conciliation'
import financial_dashboard from './financial/dashboard/financial_dashboard'
import financial_moviments from './financial/balance/financial_moviments'
import financial_solicitations from './financial/financial_solicitations/financial_solicitations'
import gains_loses from './financial/gains_loses/gains_loses'
import league_closure from './financial/league_closure/league_closure'
import league_closure_club from './financial/league_closure/league_closure_club'
import league_closure_club_detail from './financial/league_closure/league_closure_club_detail'
import parameters_agent_closure from './financial/weekly_closure/parameters_agent_closure'
import payments_annotateds from './financial/payments_annotateds/payments_annotateds'
import period_conciliation from './financial/conciliation/period_conciliation'
import period_conciliation_orders from './financial/conciliation/period_conciliation/period_conciliation_orders'
import period_conciliation_closures from './financial/conciliation/period_conciliation/period_conciliation_closures'
import period_conciliation_moviments from './financial/conciliation/period_conciliation/period_conciliation_moviments'
import period_conciliation_bank_moviments from './financial/conciliation/period_conciliation/period_conciliation_bank_moviments'
import period_conciliation_annotated from './financial/conciliation/period_conciliation/period_conciliation_annotated'
import period_conciliation_transactions from './financial/conciliation/period_conciliation/period_conciliation_transactions'
import period_conciliation_dialog_vinculation from './financial/conciliation/period_conciliation/period_conciliation_dialog_vinculation'
import periods_conciliation_dialog from './financial/conciliation/period_conciliation/periods_conciliation_dialog'
import periods_conciliation_low_dialog from './financial/conciliation/period_conciliation/period_conciliation_low_dialog'
import periods_closure from './financial/weekly_closure/periods_closure'
import periods_closure_transactions from './financial/weekly_closure/periods_closure_transactions'
import pix from './financial/pix/pix'
import players_balance from './financial/players_balance/players_balance'
import periods_players_balance from './financial/players_balance/periods_players_balance/periods_players_balance'
import receipts from './financial/receipts/receipts'
import slots_revenue from './financial/slots_revenue/slots_revenue'
import vip_sales from './financial/vip_sales/vip_sales'
import weekly_revenue from './financial/weekly_revenue/weekly_revenue'
import withdrawal from './financial/withdrawal/withdrawal'
import withdrawals_blacklist from './financial/withdrawals_blacklist/withdrawals_blacklist'

// Commercial
import agent from './commercial/agent'
import agent_slot from './commercial/agent_slot/agent_slot'
import agents from './commercial/agents'
import agent_data from './commercial/agent_data/agent_data'
import agents_folders from './commercial/agents_folders/agents_folders'
import agents_folder from './commercial/agents_folders/agents_folder'
import agent_manager from './commercial/managers/agent_manager/agent_manager'
import agents_migration from './commercial/agents_migration/agents_migration'
import agents_tree from './commercial/agents_tree/agents_tree'
import balance_agent from './commercial/balance_agent/balance_agent'
import balance_manager from './commercial/balance_manager/balance_manager'
import client from './commercial/clients/client/client'
import clients from './commercial/clients/clients'
import clients_registrations from './commercial/clients/client/clients_registrations'
import players_agent from './commercial/players_agent/players_agent'
import agent_users from './commercial/users/agent_users'
import subagents from './commercial/subagents'
import rakeback from './commercial/rakeback/rakeback'
import rake from './commercial/rake/rake'
import rake_dashboard from './commercial/dashboard/rake_dashboard'
import rake_deals from './commercial/rake_deals'
import report_agents_rake from './commercial/report/report_agents_rake'

// Store
import products from './store/products/products'
import stock from './store/stock/stock'
import shipments from './store/stock/shipments'
import sales from './store/sales/sales'

// Players
import accounts_player from './players/accounts/accounts_player'
import address_player from './players/address/address_player'
import balance_player from './players/balance_player/balance_player'
import documents_player from './players/documents/documents_player'
import player from './players/player'
import player_history from './players/player_history/player_history'
import player_ids from './players/player_ids/player_ids'
import players from './players/players'
import players_delete from './players/players_delete'

// Marketing
import publish_status from './marketing/publish_status/publish_status'
import notifications_schedule from './marketing/notifications_schedule/notifications_schedule'
import custom_clubs from './custom_clubs/custom_clubs'
import help_numbers from './help_numbers/help_numbers'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auto_attendance,
        annotateds,
        absences,
        agent_slot,
        avaliations,
        accounts_player,
        address_player,
        attendance_dashboard,
        agent,
        agent_group,
        agents,
        agent_data,
        agents_migration,
        agents_closure,
        agents_closure_detail,
        agents_folder,
        agents_folders,
        agents_tree,
        agent_manager,
        automatic_messages,
        balance,
        balance_agent,
        balance_manager,
        balance_player,
        bank,
        bank_sub_accounts,
        banks_bacen,
        billings,
        bills_to_receive,
        bills_to_pay,
        bills_items,
        bots,
        bonus,
        clubs,
        collaborator,
        collaborators,
        collaborators_equipments,
        collaborator_documents,
        companies,
        contact_chat,
        contacts,
        chat,
        client,
        clients,
        clients_registrations,
        club_closure,
        chips_transfer,
        custom_clubs,
        cost_center,
        deposit,
        documents_agent,
        documents_player,
        emails,
        equipments,
        expense,
        expense_recurrent,
        expense_types,
        extract,
        fail,
        financial_checks,
        financial_conciliation,
        financial_dashboard,
        financial_moviments,
        financial_solicitations,
        gains_loses,
        help_numbers,
        instances,
        candidates,
        jobs,
        league,
        league_closure,
        league_closure_club,
        league_closure_club_detail,
        login,
        mass_messages,
        managers,
        manager,
        manage_attendance,
        manage_annotated,
        meetings,
        notifications_schedule,
        menu,
        menus,
        message,
        occurrences,
        parameters_agent_closure,
        payments_annotateds,
        player,
        player_approval,
        player_history,
        player_ids,
        players,
        players_agent,
        players_delete,
        periods_players_balance,
        players_balance,
        period_conciliation,
        period_conciliation_orders,
        period_conciliation_closures,
        period_conciliation_moviments,
        period_conciliation_bank_moviments,
        period_conciliation_annotated,
        period_conciliation_transactions,
        period_conciliation_dialog_vinculation,
        periods_conciliation_dialog,
        periods_conciliation_low_dialog,
        periods_closure,
        periods_closure_transactions,
        pix,
        publish_status,
        products,
        profile,
        profiles,
        profile_edit,
        withdrawal,
        withdrawals_blacklist,
        weekly_revenue,
        slots_revenue,
        users,
        agent_users,
        rakeback,
        rake,
        rake_dashboard,
        rake_deals,
        receipts,
        releases,
        requests,
        report_agents_rake,
        routes,
        sales,
        shipments,
        slots,
        system_services,
        status_instance,
        stock,
        subagents,
        vip_sales,
        tomticket,
    }
})